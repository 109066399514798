import { useState } from 'react';
import { LoginForm } from './LoginForm';
import { CheckEmail } from '../CheckEmail';

type Props = {
  appName: string
}

export const Login = ({ appName }: Props) => {
  const [email, setEmail] = useState<string | null>(null);
  const [status, setStatus] = useState<'LOGIN' | 'CHECK_EMAIL'>('LOGIN');

  switch (status) {
    case 'LOGIN':
      return <LoginForm appName={appName} onSetEmail={setEmail} onSetStatus={setStatus} />;

    case 'CHECK_EMAIL':
      return <CheckEmail email={email} prev={() => setStatus('LOGIN')} action="LOGIN" />;

    default:
      return null;
  }
};
