import { StyledContainer, StyledName } from './styles';
import { WorkspaceLogo } from './WorkspaceLogo';

type Props = {
  name?: string
  logo?: string
}

export const WorkspaceName = ({ name, logo }: Props) => (
  <StyledContainer data-testid="workspace-name">
    <WorkspaceLogo name={name ?? ''} logo={logo} />
    {name && <StyledName>{name}</StyledName>}
  </StyledContainer>
);
