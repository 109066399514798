import { LogoPic, Button, Spinner } from '@quotalogic/ui';
import { useTranslation } from 'next-i18next';
import { QueryResult, useQuery, useReactiveVar } from '@apollo/client';
import type { Catalog } from '@quotalogic/gateway/types';
import { workspaceNameVar, inheritCatalogIdVar } from '../reactiveVar';
import {
  Title, Form, TextStyle, SubTextStyle, ButtonsBlock, LogoPicStyle, SpinnerStyle,
} from './styles';
import { GET_CATALOG } from './gql';

interface Props {
  token: string
  onClose: () => void
}

export const ConnectedForm = ({ token, onClose }: Props) => {
  const { t } = useTranslation('connect-catalog');
  const workspaceName = useReactiveVar(workspaceNameVar);
  const inheritCatalogId = useReactiveVar(inheritCatalogIdVar);

  const { data, error }: QueryResult<{ catalog: Catalog }, { id: string }> = useQuery(
    GET_CATALOG,
    {
      variables: { id: inheritCatalogId || token },
      skip: !inheritCatalogId,
    },
  );

  return (
    <>
      <Title>{t('title.connectedCatalog')} <br />
        {workspaceName && `«${workspaceName}»`}
      </Title>

      <Form>
        {!data && !error && <SpinnerStyle><Spinner size={20} theme="dark" /></SpinnerStyle>}
        {data && !error && (
          <>
            <LogoPicStyle>
              <LogoPic
                isEditable={false}
                src={data?.catalog?.logo}
                alt={data?.catalog?.name}
                name={data?.catalog?.name}
              />
            </LogoPicStyle>

            <TextStyle>{data?.catalog?.name}
              <SubTextStyle>{data?.catalog?.parent?.brand?.brandName}</SubTextStyle>
            </TextStyle>
          </>
        )}

        <ButtonsBlock>
          <Button view="secondary" onClick={onClose}>
            {t('button.back')}
          </Button>
          <Button>
            <a href={`/catalogs/${inheritCatalogId}`} target="_blank" rel="noreferrer">
              {t('button.workWithCatalog')}
            </a>
          </Button>
        </ButtonsBlock>
      </Form>
    </>
  );
};
