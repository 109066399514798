import { useTranslation } from 'next-i18next';
import { ErrorLayout } from './styles';

type Props = {
  code: string
}

export const RegisterError = ({ code }: Props) => {
  const { t } = useTranslation('notification');

  return (
    <ErrorLayout>
      {t(`error.${code}`)}
    </ErrorLayout>
  );
};
