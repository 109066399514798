import { FormEvent, useState, useMemo } from 'react';
import { useTranslation } from 'next-i18next';
import { FormField } from '@quotalogic/ui';
import { useMutation } from '@apollo/client';
import { UserSession } from '@quotalogic/gateway/types';
import { SIGN_IN } from './gql';
import {
  Title, FormStyle, ButtonStyle, FooterLink, LinkButton, StyledError, FieldsContainer, ButtonBlock,
} from './styles';
import { EmailInput, PasswordInput } from '../../inputs';
import { stateType } from '../../types';

type SignIn = (email: string, password: string) => void;

interface Props {
  next: (type?: stateType) => void
  prev: () => void
  setState: (text: string | null) => void
  onSetAction: (action: 'LOGIN') => void
}

export const LoginForm = ({ next, prev, setState, onSetAction }: Props) => {
  const { t } = useTranslation('login');
  const { t: errorT } = useTranslation('notification');
  const [email, setEmail] = useState<string>('');
  const [password, setPassword] = useState<string>('');
  const [error, setError] = useState<string | null>(null);

  const [login, { loading }] = useMutation<{ signIn: UserSession }>(SIGN_IN, { errorPolicy: 'all' });

  const isFormValid = useMemo(() => !!email.trim() && !!password.trim(), [email, password]);

  const signIn: SignIn = async () => {
    await login({
      variables: { email, password },
      onError: (err) => {
        if (err.graphQLErrors?.some((e) => e?.extensions?.code === 'EAC_ACCOUNT_DISABLED' || e?.extensions?.code === 'EAC_ACCOUNT_DELETED')) {
          setEmail(email);
          setState(email);
          onSetAction('LOGIN');
          next(stateType.CHECK_EMAIL);
          return;
        }

        if (err.graphQLErrors?.some((e) => e?.extensions?.code === 'EAC_INVALID_USER_DATA')) {
          setError(err.graphQLErrors[0]?.extensions?.code as string | undefined ?? 'EAC_INVALID_USER_DATA');
        }
      },
      onCompleted: (data) => {
        if (data?.signIn) {
          setError(null);
          next();
        }
      },
    });
  };

  const handleSubmit = (e: FormEvent) => {
    e.preventDefault();
    signIn && signIn(email, password);
  };

  return (
    <>
      <Title>
        {t('connectTitle')}
      </Title>

      <FormStyle onSubmit={handleSubmit}>
        <FieldsContainer>
          <FormField
            name="email"
            label={t('label.email')}
          >
            <EmailInput
              value={email}
              message={errorT<string>('error.EAC_INVALID_EMAIL')}
              onChange={(e) => setEmail(e)}
            />
          </FormField>

          <FormField
            name="password"
            label={t('label.password')}
            buttons={<LinkButton href="/password-recovery" data-testid="password-recovery-button">{t('links.forgotPass')}</LinkButton>}
          >
            <PasswordInput
              value={password}
              name="password"
              onChange={(e) => setPassword(e)}
              validation={false}
            />
          </FormField>
        </FieldsContainer>
        <ButtonBlock>
          <ButtonStyle type="submit" disabled={!isFormValid} loading={loading}>
            {t('button')}
          </ButtonStyle>
          {error && <StyledError id="error-message">{errorT('error.EAC_INVALID_USER_DATA')}</StyledError>}
        </ButtonBlock>
      </FormStyle>
      <FooterLink>
        {t('accountNotExists')}
        <button type="button" onClick={prev} data-testid="register-button">
          {t('links.sighUp')}
        </button>
      </FooterLink>
    </>
  );
};
