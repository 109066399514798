// GET_USER ?
//  ---not authorized
// NOT_AUTHORIZED_LOGIN    - login form
// NOT_AUTHORIZED_REGISTRATION  - registration form

// ---authorized
// GET_WORKSPACES ?

// AUTHORIZED_NEW_WORKSPACE - create workspace form
// AUTHORIZED_WITH_WORKSPACE - select workspace form
// CREATE_WORKSPACE ?

// CONNECTED - connected successfully
// ERROR - connected error
// GET_CATALOG ?

import { stateType } from '../types';

type State = {
  type: stateType | null;
  next: stateType | null;
  prev: stateType | null;
}

type Action = {
  type: stateType | null;
}

export const reducerState = (state: State, action: Action): State => {
  switch (action.type) {
    case stateType.NOT_AUTHORIZED_LOGIN: {
      // login
      return { type: action.type,
        next: stateType.AUTHORIZED_WITH_WORKSPACE,
        prev: stateType.NOT_AUTHORIZED_REGISTRATION };
    }
    // registration
    case stateType.NOT_AUTHORIZED_REGISTRATION: {
      return { type: action.type,
        next: stateType.CHECK_EMAIL,
        prev: stateType.NOT_AUTHORIZED_LOGIN };
    }
    // check email
    case stateType.CHECK_EMAIL: {
      return { type: action.type,
        next: null,
        prev: stateType.NOT_AUTHORIZED_LOGIN };
    }
    // select workspace if an account exists
    case stateType.AUTHORIZED_WITH_WORKSPACE: {
      return { type: action.type,
        next: stateType.CONNECTED,
        prev: stateType.AUTHORIZED_NEW_WORKSPACE };
    }
    // create workspace if an account exists
    case stateType.AUTHORIZED_NEW_WORKSPACE: {
      return { type: action.type,
        next: stateType.CONNECTED,
        prev: stateType.AUTHORIZED_WITH_WORKSPACE };
    }
    case stateType.CONNECTED: {
      return { type: action.type, next: null, prev: null };
    }
    case stateType.ERROR: {
      return { type: action.type, next: null, prev: null };
    }
    default: {
      return { type: action.type,
        next: stateType.NOT_AUTHORIZED_REGISTRATION,
        prev: null };
    }
  }
};
