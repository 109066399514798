import styled from 'styled-components';
import { GlobalColors } from '@quotalogic/ui';

export const Title = styled.h2`
  text-align: center;
  font-size: 20px;
  font-weight: 700;
  line-height: 24px;
  color: ${GlobalColors.text.primary};
`;

export const Text = styled.span`
  padding-bottom: 24px;
  text-align: center;
`;
