import { gql } from '@apollo/client';

export const SELECT_WORKSPACE = gql`
  mutation SelectWorkspace($id: ID!) {
    selectWorkspace(id: $id) {
      id
    }
  }
`;

export const GET_PERMITTED_WORKSPACE_LIST = gql`
  query CatalogWorkspaces($id: ID!) {
    catalogWorkspaces(catalogId: $id)  {
      id
      name
      logo
      permitted
      included
    }
  }
`;

export const GET_WORKSPACE = gql`
  query Workspace {
    workspace {
      id
      name
      currency
      logo
    }
  }
`;
