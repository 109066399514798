import styled from 'styled-components';
import { Button, GlobalColors } from '@quotalogic/ui';

export const Title = styled.h2`
  color: ${GlobalColors.text.primary};
  font-size: 1.429rem;
  font-weight: 700;
  line-height: 24px;
  text-align: center;
  padding: 0 14px;
`;

export const TextContainer = styled.div`
  display: flex;
  align-items: center;
  padding: 0 8px;
`;

export const StyledText = styled.span`
  font-size: 1rem;
  font-weight: 400;
  line-height: 18px;
  text-align: center;
  color: ${GlobalColors.text.primary};
`;

export const Link = styled.a`
  padding: 0 4px;
  border-radius: 8px;
  line-height: 16px;
  font-weight: 500;
  color:  ${GlobalColors.text.accent};
`;

export const FooterLink = styled.div`
  display: inline-block;
  font-weight: 500;
  white-space: nowrap;
  text-align: center;
  font-size: 12px;
  line-height: 16px;
`;

export const StyledButton = styled(Button)`
  padding: 0 4px;
`;
