import styled from 'styled-components';
import { GlobalColors } from '@quotalogic/ui';
import Link from 'next/link';

export const Title = styled.h2`
  text-align: center;
  font-size: 20px;
  font-weight: 700;
  line-height: 24px;
  color: ${GlobalColors.text.primary};
`;

export const StyledEmail = styled.span`
  font-weight: 600;
`;

export const StyledText = styled.span`
  color: ${GlobalColors.text.secondary};
  text-align: center;
`;

export const StyledLink = styled(Link)`
  font-size: 0.857rem;
  font-weight: 500;
  color: ${GlobalColors.text.accent};
`;
