import styled from 'styled-components';
import { GlobalColors } from '@quotalogic/ui';

export const Form = styled.form`
  display: flex;
  width: 100%;
  flex-direction: column;
  gap: 12px;
`;

export const ButtonsBlock = styled.div`
  display: flex;
  justify-content: stretch;
  margin-top: 12px;
  gap: 12px;
    
   & > button {
      flex: 1;
    }
`;

export const WarningIcon = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  gap: 8px;
  color: ${GlobalColors.icon.danger};
`;

export const WarningText = styled.p`
  width: 100%;
  text-align: center;
  font-size: 20px;
  font-weight: 700;
  line-height: 24px;
  color: ${GlobalColors.text.primary};
`;
