export type AuthStatusType =
  'REGISTER' | 'ACTIVATION'
  | 'TOKEN_EXPIRED'
  | 'CHECK_EMAIL'
  | 'INVALID_INVITE'
  | 'LOGIN'
  | 'USER_DISABLED'
  | 'INVITE'
  | 'RECOVERY'
  | 'CREATE_NEW_PASSWORD'
  | 'RECOVERY_SUCCESS'

export enum stateType {
  NOT_AUTHORIZED_LOGIN = 'LOGIN',
  NOT_AUTHORIZED_REGISTRATION = 'REGISTRATION',
  CHECK_EMAIL = 'CHECK_EMAIL',
  AUTHORIZED_NEW_WORKSPACE = 'NEW_WORKSPACE',
  AUTHORIZED_WITH_WORKSPACE = 'WITH_WORKSPACE',
  CONNECTED = 'CONNECTED',
  ERROR = 'ERROR',
}
