import { Avatar } from '@quotalogic/ui';
import { useTranslation } from 'next-i18next';
import { StyledEmail, StyledLink, Title } from './styles';

type Props = {
  email: string | null
}

export const DeletedAccountForm = ({ email }: Props) => {
  const { t } = useTranslation('password-recovery');

  return (
    <>
      <Title>{t('deletedAccount.title')}</Title>
      <Avatar isEditable={false} colorStyle="error" />
      <StyledEmail>{email}</StyledEmail>
      <StyledLink href="/register">{t('deletedAccount.registerLink')}</StyledLink>
    </>
  );
};
