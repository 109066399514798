import { useState } from 'react';
import { CheckEmail } from '../CheckEmail';
import { AuthStatusType } from '../types';
import { PasswordRecoveryForm } from './PasswordRecoveryForm';
import { CreatePassword } from './CreatePassword';
import { TokenExpired } from '../TokenExpired';
import { RecoverySuccess } from './RecoverySuccess';

type Props = {
  recoveryStatus: AuthStatusType
  recoveryEmail: string | null
  recoveryToken: string | null
  action: string | null
}

export const PasswordRecovery = ({ recoveryStatus, recoveryEmail, recoveryToken, action }: Props) => {
  const [email, setEmail] = useState(recoveryEmail);
  const [status, setStatus] = useState<AuthStatusType>(recoveryStatus);

  switch (status) {
    case 'RECOVERY':
      return <PasswordRecoveryForm onSetStatus={setStatus} onSetEmail={setEmail} />;

    case 'CREATE_NEW_PASSWORD':
      return <CreatePassword token={recoveryToken} email={email} onSetStatus={setStatus} action={action} />;

    case 'TOKEN_EXPIRED':
      return <TokenExpired email={email} onSetStatus={setStatus} />;

    case 'CHECK_EMAIL':
      return <CheckEmail email={email} action="RECOVERY_PASSWORD" />;

    case 'RECOVERY_SUCCESS':
      return <RecoverySuccess />;

    default:
      return null;
  }
};
