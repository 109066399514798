import { Button } from '@quotalogic/ui';
import { useMutation } from '@apollo/client';
import { useTranslation } from 'next-i18next';
import { RECOVERY_PASSWORD } from './gql';
import {
  Title, TextContainer, StyledText, Link, FooterLink,
} from './styles';

interface Props {
  email: string | null
  onSetStatus: (status: 'CHECK_EMAIL') => void
}
export const TokenExpired = ({ email, onSetStatus }: Props) => {
  const { t } = useTranslation('password-recovery');
  const [recoveryPassword] = useMutation(RECOVERY_PASSWORD);
  const resetLink = async () => {
    await recoveryPassword({
      variables: {
        email,
      },
    });

    onSetStatus && onSetStatus('CHECK_EMAIL');
  };

  return (
    <>
      <Title>
        {t('expiredLink.title')}
      </Title>
      <TextContainer>
        <StyledText>
          {t('expiredLink.text')}
        </StyledText>
      </TextContainer>
      <Button
        width="100%"
        onClick={resetLink}
      >
        {t('expiredLink.button')}
      </Button>

      <FooterLink>
        <Link href="/login">{t('expiredLink.link')}</Link>{t('expiredLink.textBottom')}
      </FooterLink>
    </>
  );
};
