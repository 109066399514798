import { FormEvent, useState, useMemo } from 'react';
import { useTranslation } from 'next-i18next';
import { FormField } from '@quotalogic/ui';
import { useMutation } from '@apollo/client';
import {
  Title, FormStyle, FooterLink, Link, ButtonStyle, TextBlock, ButtonBlock,
} from './styles';
import { REGISTER } from './gql';
import { AuthStatusType } from '../../types';
import { EmailInput } from '../../inputs';

interface RegisterFormProps {
  appName: string
  onSetEmail: (email: string) => void
  onSetStatus: (status: AuthStatusType) => void
}

export const RegisterForm = ({ appName, onSetEmail, onSetStatus }: RegisterFormProps) => {
  const { t } = useTranslation('register');
  const { t: errorT } = useTranslation('notification');
  const [email, setEmail] = useState<string>('');
  const [register, { loading }] = useMutation<{ register: boolean }>(REGISTER, { errorPolicy: 'all' });

  const isFormValid = useMemo(() => !!email, [email]);

  const handleSubmit = async (e: FormEvent) => {
    e.preventDefault();
    if (email) {
      await register({
        variables: {
          email,
        },
        update(_, { data }) {
          if (data?.register) {
            onSetEmail(email);
            onSetStatus('CHECK_EMAIL');
          }
        },
      });
    }
  };

  return (
    <>
      <Title>
        {t('register.title', { name: appName })}
      </Title>

      <FormStyle onSubmit={handleSubmit}>
        <FormField
          name="email"
          label={t('register.label.email')}
        >
          <EmailInput
            value={email}
            message={errorT<string>('error.EAC_INVALID_EMAIL')}
            onChange={(e) => setEmail(e)}
          />
        </FormField>

        <ButtonBlock>
          <ButtonStyle
            type="submit"
            disabled={!isFormValid}
            data-testid="submit"
            loading={loading}
            width="100%"
          >
            {t('controls.register')}
          </ButtonStyle>
          <TextBlock>
            {t('register.text')}
            <Link href="#">{t('register.links.termsOfUse')}</Link>
            {t('register.and')}
            <Link href="#">{t('register.links.privacyPolicy')}</Link>
          </TextBlock>
        </ButtonBlock>
      </FormStyle>

      <FooterLink>
        {t('register.footerText')}<Link href="/login">{t('register.links.logIn')}</Link>
      </FooterLink>
    </>
  );
};
