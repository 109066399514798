import styled from 'styled-components';
import { GlobalColors } from '@quotalogic/ui';

export const StyledContainer = styled.div`
  display: flex;
  padding: 8px;
  align-items: center;
  border-radius: 10px;
  gap: 8px;
  background: ${GlobalColors.bg.darkBlue.ghostHover};
`;

export const StyledName = styled.span`
  font-weight: 500;
  line-height: 16px;
`;

export const ContainerStyle = styled.div`
  width: 32px;
  height: 32px;
  border-radius: 6px;
  box-sizing: border-box;
  display: flex;
  justify-content: center;
  font-weight: 600;
  letter-spacing: 0.05em;
  overflow: hidden;
  align-items: center;
  background: ${GlobalColors.bg.blue.secondary};
`;

export const ImageStyle = styled.img`
  object-fit: contain;
`;

export const TextStyle = styled.span`
  text-transform: uppercase;
  color: ${GlobalColors.text.accent};
`;
