import { useTranslation } from 'next-i18next';
import { FooterLink, StyledButton, StyledText, TextContainer, Title } from './styles';

type Props = {
  onSetStatus: (status: 'LOGIN') => void
}

export const InvalidInvitation = ({ onSetStatus }: Props) => {
  const { t } = useTranslation('invite');

  return (
    <>
      <Title>
        {t('workspace.title')}
      </Title>
      <TextContainer>
        <StyledText>
          {t('workspace.text')}
        </StyledText>
      </TextContainer>

      <FooterLink>
        {t('workspace.prev')}
        <StyledButton
          view="link"
          onClick={() => onSetStatus('LOGIN')}
        >
          {t('workspace.link')}
        </StyledButton>
        {t('workspace.post')}
      </FooterLink>
    </>
  );
};
