import { FormEvent, useMemo, useState } from 'react';
import { useMutation } from '@apollo/client';
import { useTranslation } from 'next-i18next';
import { Input, FormField } from '@quotalogic/ui';
import { RECOVERY_PASSWORD } from './gql';
import {
  Title, FormStyle, ButtonStyle, FooterLink, Link, SubTitle,
} from './styles';
import { AuthStatusType } from '../../types';

interface Props {
  onSetEmail: (email: string) => void
  onSetStatus: (status: AuthStatusType) => void
}

export const PasswordRecoveryForm = ({ onSetEmail, onSetStatus }: Props) => {
  const { t } = useTranslation('password-recovery');
  const { t: errorT } = useTranslation('notification');
  const [email, setEmail] = useState('');
  const [isEmailValid, setIsEmailValid] = useState(true);
  const [recoveryPassword, { loading }] = useMutation<{ recoveryPassword: boolean }>(RECOVERY_PASSWORD, { errorPolicy: 'all' });

  const isFormValid = useMemo(() =>
    isEmailValid && email, [email, isEmailValid]);

  const updateEmail = (value: string) => {
    setIsEmailValid(true);
    setEmail(value);
  };

  const handleEmailValid = () => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;

    setIsEmailValid(email.trim() === '' || emailRegex.test(email));
  };

  const handleSubmit = async (e: FormEvent) => {
    e.preventDefault();
    if (isFormValid) {
      await recoveryPassword({
        variables: {
          email,
        },
        onError(err) {
          if (err.graphQLErrors?.some((e) => e?.extensions.code === 'EAC_USER_DISABLED')) {
            onSetEmail(email);
            onSetStatus('CHECK_EMAIL');
          }
        },
        update(_, { data }) {
          if (data?.recoveryPassword) {
            onSetEmail(email);
            onSetStatus('CHECK_EMAIL');
          }
        },
      });
    }
  };

  return (
    <>
      <Title>
        {t('passwordRecovery.title')}
      </Title>

      <SubTitle>{t('passwordRecovery.text')}</SubTitle>

      <FormStyle onSubmit={handleSubmit}>
        <FormField
          name="email"
          label={t('passwordRecovery.label.email')}
        >
          <Input
            type="text"
            name="email"
            status={isEmailValid ? 'default' : 'error'}
            message={isEmailValid ? undefined : errorT<string>('error.EAC_INVALID_EMAIL')}
            required
            onChange={updateEmail}
            onBlur={handleEmailValid}
          />
        </FormField>

        <ButtonStyle
          type="submit"
          loading={loading}
          disabled={!isFormValid}
        >
          {t('passwordRecovery.button')}
        </ButtonStyle>
      </FormStyle>

      <FooterLink>
        {t('passwordRecovery.footerText')}
        <Link href="/login">{t('passwordRecovery.link')}</Link>
      </FooterLink>
    </>
  );
};
