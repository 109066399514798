import { Button } from '@quotalogic/ui';
import { useTranslation } from 'next-i18next';
import { CircleExclamationFill } from '@gravity-ui/icons';
import { Form, ButtonsBlock, WarningIcon, WarningText } from './styles';

interface Props {
  onClose: () => void
}

export const ErrorForm = ({ onClose }: Props) => {
  const { t } = useTranslation('connect-catalog');

  return (
    <Form>
      <WarningIcon>
        <CircleExclamationFill width={42} height={42} />
        <WarningText>{t('title.errorText')}</WarningText>
      </WarningIcon>

      <ButtonsBlock>
        <Button view="primary" onClick={onClose}>
          Ok
        </Button>
      </ButtonsBlock>
    </Form>
  );
};
