import { useTranslation } from 'next-i18next';
import {
  Title, StyledTopText, StyledEmail, TextContainer, StyledBottomText,
} from './styles';
import { LinkButton } from './LinkButton';
import { ResendButton } from './ResendButton';

interface Props {
  email: string | null
  action: 'LOGIN' | 'REGISTER' | 'RECOVERY_PASSWORD'
  prev?: () => void
}
export const CheckEmail = ({ email, prev, action }: Props) => {
  const { t } = useTranslation('password-recovery');

  return (
    <>
      <Title>
        {t('checkEmail.title')}
      </Title>
      <TextContainer>
        <StyledTopText>{t('checkEmail.body.text')}</StyledTopText>
        <StyledEmail>{email}</StyledEmail>
      </TextContainer>
      <TextContainer>
        <StyledBottomText>
          {t('checkEmail.footer.noEmailText')}
          <ResendButton email={email} action={action} />
        </StyledBottomText>
        <StyledBottomText style={{ paddingTop: '12px' }}>
          {t('checkEmail.footer.changedMind')}
        </StyledBottomText>
        <StyledBottomText>
          <LinkButton prev={prev} />
          {t('checkEmail.footer.singInText')}
        </StyledBottomText>
      </TextContainer>
    </>
  );
};
