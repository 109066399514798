import { gql } from '@apollo/client';

export const CREATE_WORKSPACE = gql`
  mutation CreateWorkspace($name: String!, $currency: String!, $timeZone: String!) {
    createWorkspace(name: $name, currency: $currency, timeZone: $timeZone) {
      id
      name
      role
      currency
    }
  }
`;