import { FormEvent, useState, useMemo } from 'react';
import { useTranslation } from 'next-i18next';
import { FormField } from '@quotalogic/ui';
import { useMutation } from '@apollo/client';
import type { User } from '@quotalogic/gateway/types';
import {
  Title, FormStyle, FooterLink, Link, ButtonStyle, TextBlock, ButtonBlock,
} from './styles';
import { RegisterError } from './RegisterError';
import { REGISTER } from './gql';
import { EmailInput } from '../../inputs';

interface Props {
  next: () => void
  prev: () => void
  setState: (text: string | null) => void
  onSetAction: (action: 'REGISTER') => void
  token: string
}

export const RegisterForm = ({ prev, next, setState, token, onSetAction }: Props) => {
  const { t } = useTranslation('register');
  const { t: errorT } = useTranslation('notification');
  const [email, setEmail] = useState<string>('');
  const [register, { loading }] = useMutation<{ register: User }>(REGISTER, { errorPolicy: 'all' });

  const [error, setError] = useState<string | null>(null);

  const isFormValid = useMemo(() => !!email.trim(), [email]);

  const handleSubmit = async (e: FormEvent) => {
    e.preventDefault();
    if (isFormValid) {
      await register({
        variables: {
          email,
          catalogProfileToken: token,
        },
        update(_, { data }) {
          if (data?.register) {
            setState(email);
            onSetAction('REGISTER');
            next();
          }
        },
        onError: (err) => {
          if (err?.graphQLErrors[0]?.extensions?.code) {
            setError(err.graphQLErrors[0].extensions.code as string);
          } else {
            setError('EAC_REGISTER_FAILED');
          }
        },
        onCompleted: () => {
          setError(null);
        },
      });
    }
  };

  return (
    <>
      <Title>
        {t('register.titleConnect')}
      </Title>

      <FormStyle onSubmit={handleSubmit}>
        <FormField
          name="email"
          label={t('register.label.email')}
        >
          <EmailInput
            value={email}
            message={errorT<string>('error.EAC_INVALID_EMAIL')}
            onChange={(e) => setEmail(e)}
          />
        </FormField>

        <ButtonBlock>
          <ButtonStyle
            type="submit"
            disabled={!isFormValid}
            data-testid="submit"
            loading={loading}
            width="100%"
          >
            {t('controls.register')}
          </ButtonStyle>
          {error && <RegisterError code={error} />}
          <TextBlock>
            {t('register.text')}
            <Link href="#">{t('register.links.termsOfUse')}</Link>
            {t('register.and')}
            <Link href="#">{t('register.links.privacyPolicy')}</Link>
          </TextBlock>
        </ButtonBlock>
      </FormStyle>

      <FooterLink>
        {t('register.footerText')}
        <button type="button" onClick={prev}>
          {t('register.links.logIn')}
        </button>
      </FooterLink>
    </>
  );
};
