import { gql } from '@apollo/client';

export const RESET_PASSWORD = gql`
  mutation ResetPassword($email: String!, $password: String!, $token: String!) {
    resetPassword(email: $email, password: $password, token: $token) {
      id
    }
  }
`;

export const RESTORE_PASSWORD = gql`
  mutation RestorePassword($email: String!, $password: String!, $restoreToken: String!) {
    restorePassword(email: $email, password: $password, restoreToken: $restoreToken) {
      id
    }
  }
`;
