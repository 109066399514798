import dayjs from 'dayjs';
import { useState } from 'react';
import { useMutation } from '@apollo/client';
import { Autocomplete, Input, FormField, Spinner } from '@quotalogic/ui';
import { useTranslation } from 'next-i18next';
import timezone from 'dayjs/plugin/timezone';
import { timezones, Timezone } from '@quotalogic/common';
import { Workspace } from '@quotalogic/gateway/types';
import { CurrencySelector } from '@quotalogic/widgets';
import { Form, Controls, Title, Link, LoadingStyle } from './styles';
import { CREATE_WORKSPACE } from './gql';
import { workspaceNameVar } from '../reactiveVar';
import { ConnectCatalog } from '../ConnectCatalog';

dayjs.extend(timezone);

interface Props {
  next: () => void
  prev: () => void
  token: string
  defaultCurrency: string
  errorFn: () => void
}

export const CreateWorkspace = ({ prev, next, token, defaultCurrency, errorFn }: Props) => {
  const { t } = useTranslation('connect-catalog');
  const [name, setName] = useState('');
  const [isConnecting, setIsConnecting] = useState(false);

  const [createWorkspaceMutation] = useMutation<{ createWorkspace: Workspace }>(
    CREATE_WORKSPACE,
    { errorPolicy: 'all' },
  );
  const [activeCurrency, setActiveCurrency] = useState(defaultCurrency);
  const [timeZone, setTimezone] = useState<{ label: string, value: string }|null>(
    timezones.find((tz) => tz.value === dayjs.tz.guess()) ?? null,
  );

  const createWorkspace = async () => {
    if (timeZone) {
      workspaceNameVar(name);
      return await createWorkspaceMutation({
        variables: {
          name,
          currency: activeCurrency.length > 0 ? activeCurrency : defaultCurrency,
          timeZone: timeZone.value,
        },
      });
    }
    return null;
  };

  return (
    <>
      {isConnecting && (
        <LoadingStyle><Spinner size={20} theme="dark" /> {t('label.connection')}</LoadingStyle>
      )}

      <Title>
        {t('title.newWorkspace')}
      </Title>

      <Form>
        <FormField
          name="workspaceName"
          rowLabelSize="short"
          label={t('label.name')}
        >
          <Input
            type="text"
            name="name"
            $size="m"
            value={name}
            onChange={(e) => setName(e)}
          />
        </FormField>

        <FormField
          name="timezone"
          label={t('label.timezone')}
          rowLabelSize="short"
        >
          <Autocomplete<Timezone>
            list={timezones}
            value={timeZone}
            onChange={setTimezone}
          />
        </FormField>

        <FormField
          name="currency"
          label={t('label.currency')}
          rowLabelSize="short"
        >
          <CurrencySelector onChange={setActiveCurrency} value={activeCurrency} />
        </FormField>

        <Controls>
          <ConnectCatalog
            token={token}
            isDisabled={name.trim().length === 0 || isConnecting}
            nextStep={next}
            errorFn={errorFn}
            callback={createWorkspace}
            setIsConnecting={setIsConnecting}
          />
          <Link href="/login">{t('button.signInNoConnect')}</Link>
        </Controls>
      </Form>
    </>
  );
};
