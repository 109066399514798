import { useTranslation } from 'next-i18next';
import { FormEvent, useMemo, useReducer, useState } from 'react';
import { Checkbox, FormField, Input } from '@quotalogic/ui';
import { useMutation } from '@apollo/client';
import { ActivateAccountResponse } from '@quotalogic/gateway/types/User';
import {
  ButtonStyle, CheckBlock, FormStyle, Title, Link, TextBlock, ButtonBlock, Text, FooterLink,
} from './styled';
import { ACTIVATE_ACCOUNT } from './gql';
import { WorkspaceName } from './WorkspaceName';
import { ActivationDataType } from '../../utils';
import { DeclineInviteButton } from './DeclineInviteButton';
import { PasswordInput } from '../../inputs';
import { passwordReducer } from '../../inputs/PasswordInput';
import { $emitter } from '@quotalogic/intercom';

type Props = {
  token: string | null
  appName: string
  onSetStatus: (status: 'CHECK_EMAIL') => void
  email: string | null
  action: string | null
  activationData: ActivationDataType | null
}

export const ActivateAccount = ({ token, appName, onSetStatus, email, action, activationData }: Props) => {
  const { t } = useTranslation('register');
  const { t: errorT } = useTranslation('notification');
  const [name, setName] = useState<string>('');
  const [password, dispatchPassword] = useReducer(passwordReducer, { value: '', isValid: true });
  const [activateAccount, { loading }] = useMutation<{ activateAccount?: ActivateAccountResponse }>(ACTIVATE_ACCOUNT);

  const isFormValid = useMemo(() => name.trim() && password.value.trim() && password.isValid, [name, password]);

  const handleSubmit = async (e: FormEvent) => {
    e.preventDefault();
    if (isFormValid) {
      await activateAccount({
        variables: {
          email,
          token,
          name,
          password: password.value,
          workspaceName: action === 'invite' ? undefined : t<string>('activate.defaultWorkspaceName'),
          timezone: action === 'invite' ? undefined : Intl.DateTimeFormat().resolvedOptions().timeZone,
        },
        onCompleted(data) {
          if (action === 'invite') $emitter.emit('REFETCH_NOTIFICATIONS');
          if (!data.activateAccount?.workspaceId) {
            onSetStatus('CHECK_EMAIL');
          } else if (data.activateAccount?.catalogId) {
            window.location.href = `/catalogs/${data.activateAccount.catalogId}`;
          } else {
            window.location.href = '/';
          }
        },
      });
    }
  };

  return (
    <>
      <Title>
        {action === 'invite' ? t('activate.inviteTitle', { name: appName }) : t('activate.title')}
      </Title>
      {action === 'invite' && (
        <>
          <Text>{t('activate.text')}</Text>
          <WorkspaceName name={activationData?.workspaceName} logo={activationData?.workspaceLogo} />
        </>
      )}

      <FormStyle onSubmit={handleSubmit}>
        {action === 'invite' && (
          <FormField name="email" label={t('activate.label.email')}>
            <Input type="text" name="email" value={email as string} disabled />
          </FormField>
        )}

        <FormField name="name" label={t('activate.label.name')}>
          <Input type="text" name="name" value={name} onChange={(e) => setName(e)} required />
        </FormField>

        <FormField name="password" label={t('activate.label.password')}>
          <PasswordInput
            name="password"
            value={password.value}
            message={errorT<string>('error.EAC_INVALID_PASSWORD_LENGTH')}
            onChange={(payload) => dispatchPassword({ type: 'SET_VALUE', payload })}
            onBlur={(payload) => dispatchPassword({ type: 'SET_VALIDITY', payload })}
          />
        </FormField>

        <CheckBlock style={{ color: 'lightGray' }}>
          <Checkbox name="subscribeMails" size="sm" disabled />
          {t('activate.sendMeEmails', { name: appName })}
        </CheckBlock>

        <ButtonBlock>
          <ButtonStyle
            type="submit"
            width="100%"
            loading={loading}
          >
            {action === 'invite' ? t('controls.singUp') : t('controls.register')}
          </ButtonStyle>
          <TextBlock>
            {t('register.text')}
            <Link href="#">{t('register.links.termsOfUse')}</Link>
            {t('register.and')}
            <Link href="#">{t('register.links.privacyPolicy')}</Link>
          </TextBlock>
        </ButtonBlock>
      </FormStyle>

      {action === 'invite' && (
        <FooterLink>
          {t('activate.footerText')}
          <DeclineInviteButton inviteToken={activationData?.inviteToken} email={email} />
        </FooterLink>
      )}
    </>
  );
};
