import styled from 'styled-components';
import { Button, GlobalColors } from '@quotalogic/ui';

export const Title = styled.h2`
  text-align: center;
  font-size: 20px;
  font-weight: 700;
  line-height: 24px;
  color: ${GlobalColors.text.primary};
`;

export const Text = styled.span`
  font-weight: 700;
  line-height: 16px;
  text-align: center;
  padding: 0 36px;
`;

export const FormStyle = styled.form`
  display: flex;
  flex-direction: column;
  gap: 16px;
`;

export const ButtonStyle = styled(Button)`
  align-self: center;
  justify-content: center;
`;

export const CheckBlock = styled.div`
  display: flex;
  gap: 8px;
  font-size: 12px;
  line-height: 16px;
  
  a {
    padding: 0;
  }
`;

export const TextBlock = styled.div`
  text-align: center;
  font-size: 12px;
  font-weight: 500;
  line-height: 16px;
`;

export const Link = styled.a`
  padding: 0 4px;
  border-radius: 8px;
  line-height: 16px;
  font-weight: 500;
  color:  ${GlobalColors.text.accent};
`;

export const ButtonBlock = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
`;

export const FooterLink = styled.div`
  display: flex;
  flex-direction: column;
  font-weight: 500;
  white-space: nowrap;
  text-align: center;
  font-size: 12px;
  line-height: 16px;
`;

export const LinkButton = styled(ButtonStyle)`
  padding: 0;
`;
