import styled from 'styled-components';
import { GlobalColors } from '@quotalogic/ui';

export const FormStyle = styled.form`
  display: flex;
  width: 100%;
  flex-direction: column;
`;

export const Title = styled.h1`
  font-size: 20px;
  font-style: normal;
  font-weight: 700;
  line-height: 24px; 
  text-align: center;
`;

export const ControlsBlock = styled.div`
  display: flex;
  flex-direction: column;
  text-align: center;
  justify-content: center;
  gap: 12px;
  margin-top: 24px;
`;

export const ListWrapper = styled.div`
  max-height: 200px;
  overflow-y: auto;
  padding-right: 4px;
    
    &::-webkit-scrollbar {
        width: 4px;
        padding: 0;
        background: #f2f2f2;
    },
    &::-webkit-scrollbar-thumb {
        background-color: rgba(13, 45, 94, 0.08);
        border-radius: 20px;
        border: 3px solid rgba(13, 45, 94, 0.08);
    },
`;

export const Link = styled.a`
  padding: 0 8px;
  border-radius: 8px;
  font-size: 12px;
  line-height: 16px;
  font-weight: 500;
  color:  ${GlobalColors.text.accent};
`;

export const LoadingStyle = styled.div`
  position: absolute;
  top: 20px;
  left: 10px;
  width: 420px;
  height: 90%;
  display: flex;
  z-index: 300;
  justify-content: center;
  align-items: center;
  gap: 8px;
  color: ${GlobalColors.icon.primary};
  background: rgba(255, 255, 255, 0.75);
  font-size: 14px;
  font-weight: 400;
  line-height: 16px;
`;
