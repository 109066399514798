import { gql } from '@apollo/client';

export const GET_INHERIT_STATUS = gql`
  query GetInheritStatus($inheritId: ID!) {
    catalogInheritStatus(inheritId: $inheritId) {
      id
      status
      entityId
    }
  }
`;

export const INHERIT_CATALOG = gql`
  mutation InheritCatalog($token: ID!) {
    inheritCatalog(token: $token) {
      id
    }
  }
`;
