import Link from 'next/link';
import { useTranslation } from 'next-i18next';
import { StyledButton } from './styles';

type Props = {
  prev?: () => void
}

export const LinkButton = ({ prev }: Props) => {
  const { t } = useTranslation('password-recovery');

  if (prev) {
    return (
      <StyledButton
        type="button"
        view="link"
        onClick={prev}
      >
        {t('checkEmail.footer.logOutLink')}
      </StyledButton>
    );
  }

  return (
    <StyledButton
      type="button"
      view="link"
    >
      <Link href="/login">{t('checkEmail.footer.logOutLink')}</Link>
    </StyledButton>
  );
};
