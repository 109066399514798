import { useTranslation } from 'next-i18next';
import { useMutation } from '@apollo/client';
import { LinkButton } from '../styled';
import { DELETE_ACTIVATE_TOKEN } from './gql';
import { $emitter } from '@quotalogic/intercom';

type Props = {
  inviteToken?: string
  email?: string | null
}

export const DeclineInviteButton = ({ inviteToken, email }: Props) => {
  const { t } = useTranslation('register');
  const [deleteActivateToken] = useMutation<{ deleteActivateToken: boolean }>(DELETE_ACTIVATE_TOKEN);

  const handleDecline = async () => {
    await deleteActivateToken({
      variables: {
        token: inviteToken,
        email,
      },
      onCompleted(data) {
        if (data.deleteActivateToken) {
          $emitter.emit('REFETCH_NOTIFICATIONS');
          window.location.href = '/login';
        }
      },
    });
  };

  return (
    <LinkButton type="button" view="link" onClick={handleDecline}>
      {t('activate.footerButton')}
    </LinkButton>
  );
};
