import { gql } from '@apollo/client';

export const ACTIVATE_ACCOUNT = gql`
  mutation ActivateAccount($email: String!, $token: ID!, $name: String!,  $password: String!, $workspaceName: String, $timezone: String) {
    activateAccount(email: $email, token: $token, name: $name, password: $password, workspaceName: $workspaceName, timezone: $timezone) {
      userId
      workspaceId
      catalogId
      eventId
    }
  }
`;
