import { useReducer, useMemo, useEffect, useCallback, useState } from 'react';
import { GlobalColors, Modal } from '@quotalogic/ui';
import { ChevronLeft } from '@gravity-ui/icons';
import { MoreButton } from './styles';
import { reducerState } from './reducer';
import { LoginForm } from './LoginForm';
import { ErrorForm } from './ErrorForm';
import { LayoutForm } from './LayoutForm';
import { RegisterForm } from './RegisterForm';
import { CheckEmail } from '../CheckEmail';
import { ConnectedForm } from './ConnectedForm';
import { SelectWorkspace } from './SelectWorkspace';
import { CreateWorkspace } from './CreateWorkspace';
import { stateType } from '../types';

interface Props {
  onClose: () => void
  isVisible: boolean
  defaultState: stateType
  token: string
  hasWorkspaces: boolean
  currency: string
  id: string
}

export const Connect = ({ onClose, defaultState, token, isVisible, hasWorkspaces, currency, id }: Props) => {
  const [email, setEmail] = useState<string | null>('');
  const [state, dispatch] = useReducer(
    reducerState,
    { type: stateType.NOT_AUTHORIZED_LOGIN,
      next: stateType.AUTHORIZED_WITH_WORKSPACE,
      prev: stateType.NOT_AUTHORIZED_REGISTRATION },
  );
  const [action, setAction] = useState<'LOGIN' | 'REGISTER'>('LOGIN');

  const errorHandler = useCallback(() => dispatch({ type: stateType.ERROR }), []);

  useEffect(() => {
    dispatch({ type: defaultState });
  }, [defaultState]);

  const FormComponent = useMemo(() => {
    const { next, prev, reset } = {
      next: (type?: stateType) => dispatch({ type: type ?? state.next }),
      prev: () => dispatch({ type: state.prev }),
      reset: () => dispatch({ type: stateType.NOT_AUTHORIZED_LOGIN }),
    };
    switch (state.type) {
      case stateType.NOT_AUTHORIZED_REGISTRATION: {
        return <RegisterForm {...{ next, prev }} setState={setEmail} token={token} onSetAction={setAction} />;
      }
      case stateType.CHECK_EMAIL: {
        return <CheckEmail {...{ prev }} email={email} action={action} />;
      }
      case stateType.AUTHORIZED_NEW_WORKSPACE: {
        return <CreateWorkspace {...{ next, prev }} token={token} defaultCurrency={currency} errorFn={errorHandler} />;
      }
      case stateType.AUTHORIZED_WITH_WORKSPACE: {
        return <SelectWorkspace {...{ next, prev, reset }} token={token} id={id} errorFn={errorHandler} />;
      }
      case stateType.CONNECTED: {
        return <ConnectedForm token={token} onClose={onClose} />;
      }
      case stateType.ERROR: {
        return <ErrorForm onClose={onClose} />;
      }
      case stateType.NOT_AUTHORIZED_LOGIN:
      default: {
        return <LoginForm {...{ next, prev }} setState={setEmail} onSetAction={setAction} />;
      }
    }
  }, [action, currency, email, errorHandler, id, onClose, state.next, state.prev, state.type, token]);

  return (
    <Modal
      size="newDefault"
      isVisible={isVisible}
      onClose={onClose}
      isCentred
    >
      {state.type === stateType.AUTHORIZED_NEW_WORKSPACE && hasWorkspaces && (
        <MoreButton view="ghost" size="large" onClick={() => dispatch({ type: stateType.AUTHORIZED_WITH_WORKSPACE })}>
          <ChevronLeft width={20} height={20} color={GlobalColors.icon.disable} />
        </MoreButton>
      )}
      <LayoutForm>{FormComponent}</LayoutForm>
    </Modal>
  );
};
