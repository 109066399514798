import { useMemo } from 'react';
import { ContainerStyle, ImageStyle, TextStyle } from './styles';

type Props = {
  name: string
  logo?: string
}

export const WorkspaceLogo = ({ name, logo }: Props) => {
  const hasLogo = useMemo(() => Boolean(logo && logo.length > 0), [logo]);

  return (
    <ContainerStyle>
      { hasLogo
        ? <ImageStyle src={logo} />
        : (
          <TextStyle>
            {name.length > 1 ? name[0] + name[1] : name[0]}
          </TextStyle>
        )}
    </ContainerStyle>
  );
};
