import dayjs from 'dayjs';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { QueryResult, useMutation, useQuery } from '@apollo/client';
import { Plus } from '@gravity-ui/icons';
import { Button, SelectorListItem, Spinner } from '@quotalogic/ui';
import { useTranslation } from 'next-i18next';
import timezone from 'dayjs/plugin/timezone';
import { SelectorList } from '@quotalogic/ui/Selector/list';
import { Workspace } from '@quotalogic/gateway/types';
import { WorkspaceCatalog } from '@quotalogic/gateway/types/Workspace';
import {
  Title, FormStyle, ControlsBlock, ListWrapper, Link, LoadingStyle,
} from './styles';
import { SELECT_WORKSPACE, GET_WORKSPACE, GET_PERMITTED_WORKSPACE_LIST } from './gql';
import { workspaceNameVar } from '../reactiveVar';
import { ConnectCatalog } from '../ConnectCatalog';

dayjs.extend(timezone);

interface Props {
  next: () => void
  prev: () => void
  reset: () => void
  token: string
  id: string
  errorFn: () => void;
}

export const SelectWorkspace = ({ next, prev, token, reset, id, errorFn }: Props) => {
  const { t } = useTranslation('connect-catalog');
  const [selectedWorkspace, setSelectedWorkspace] = useState<string | undefined>();
  const [isConnecting, setIsConnecting] = useState(false);

  const { data }: QueryResult<{ workspace: Workspace }> = useQuery(GET_WORKSPACE, {
    errorPolicy: 'all',
  });

  const { data: list, loading: loadingList } = useQuery<{ catalogWorkspaces: WorkspaceCatalog[] }, { id: string }>(GET_PERMITTED_WORKSPACE_LIST, {
    variables: { id },
    errorPolicy: 'all',
    fetchPolicy: 'cache-and-network',
    onError: (err) => {
      if (err.graphQLErrors) {
        err.graphQLErrors.forEach((error) => {
          if (error.extensions?.code === 'NOT_AUTHORIZED') {
            reset();
          }
        });
      }
    },
  });

  useEffect(() => {
    const workspace = list?.catalogWorkspaces?.find((item) => (item.id === data?.workspace?.id));
    const isIncluded = workspace?.included;
    const isPermitted = workspace?.permitted;

    if (data?.workspace?.id && !isIncluded && isPermitted) {
      workspaceNameVar(data?.workspace?.name);
      setSelectedWorkspace(data?.workspace?.id);
    } else {
      workspaceNameVar(undefined);
      setSelectedWorkspace(undefined);
    }
  }, [data?.workspace?.id, data?.workspace?.name, list?.catalogWorkspaces]);

  const workspaceList = useMemo<SelectorListItem[]>(() => list?.catalogWorkspaces?.map((item) => ({
    id: item.id,
    icon: item?.logo,
    text: item.name,
    isSelected: item.included || item.id === selectedWorkspace,
    disabled: item.included || !item.permitted,
  })) ?? [], [list?.catalogWorkspaces, selectedWorkspace]);

  const [selectWorkspace] = useMutation(SELECT_WORKSPACE);

  const activateWorkspace = useCallback(async () => {
    await selectWorkspace({
      variables: { id: selectedWorkspace },
    });
  }, [selectedWorkspace]);

  const selectHandler = useCallback((id: string) => {
    setSelectedWorkspace(id);
    const selected = list?.catalogWorkspaces?.find((item: WorkspaceCatalog) => item.id === id);
    workspaceNameVar(selected?.name);
  }, [list?.catalogWorkspaces]);

  return (
    <>
      {isConnecting && (
        <LoadingStyle><Spinner size={20} theme="dark" /> {t('label.connection')}</LoadingStyle>
      )}

      <Title>{t('title.selectWorkspace')}</Title>

      <FormStyle>
        <ListWrapper>
          <SelectorList list={workspaceList} isLoading={loadingList} onSelect={selectHandler} icon="logo" />
        </ListWrapper>

        <Button onClick={prev} view="ghost" iconLeft={<Plus width={14} height={14} />}>
          {t('button.createWorkspace')}
        </Button>

        <ControlsBlock>
          <ConnectCatalog
            token={token}
            nextStep={next}
            setIsConnecting={setIsConnecting}
            callback={activateWorkspace}
            isDisabled={!selectedWorkspace}
            errorFn={errorFn}
          />

          <Link href="/login">{t('button.signInNoConnect')}</Link>
        </ControlsBlock>
      </FormStyle>
    </>
  );
};
