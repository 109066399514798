import { gql } from '@apollo/client';

export const RECOVERY_PASSWORD = gql`
  mutation RecoveryPassword($email: String!) {
    recoveryPassword(email: $email)
  }
`;

export const REGISTER = gql`
  mutation Register($email: String!, $catalogProfileToken: String) {
    register(email: $email, catalogProfileToken: $catalogProfileToken)
  }
`;

export const RECOVERY_ACCOUNT = gql`
  mutation RecoveryAccount($email: String!) {
    recoveryAccount(email: $email)
  }
`;
