import { useTranslation } from 'next-i18next';
import { useMutation } from '@apollo/client';
import { useCallback, useEffect, useRef, useState } from 'react';
import { RECOVERY_ACCOUNT, RECOVERY_PASSWORD, REGISTER } from './gql';
import { StyledButton } from './styles';

type Props = {
  email: string | null
  action: 'LOGIN' | 'REGISTER' | 'RECOVERY_PASSWORD'
}

export const ResendButton = ({ email, action }: Props) => {
  const { t } = useTranslation('password-recovery');
  const [recoveryAccount] = useMutation<{ recoveryAccount: boolean }>(RECOVERY_ACCOUNT, { errorPolicy: 'all' });
  const [register] = useMutation<{ register: boolean }>(REGISTER, { errorPolicy: 'all' });
  const [recoveryPassword] = useMutation<{ recoveryPassword: boolean }>(RECOVERY_PASSWORD, { errorPolicy: 'all' });
  const [countdown, setCountdown] = useState(0);
  const isButtonDisabled = countdown > 0;

  const resetLink = useCallback(async () => {
    try {
      switch (action) {
        case 'REGISTER':
          await register({ variables: { email } });
          break;

        case 'RECOVERY_PASSWORD':
          await recoveryPassword({ variables: { email } });
          break;

        case 'LOGIN':
        default:
          await recoveryAccount({ variables: { email } });
          break;
      }
      setCountdown(60);
    } catch (error) {
      setCountdown(0);
    }
  }, [action, recoveryAccount, register, email, recoveryPassword]);

  const timerRef = useRef<NodeJS.Timeout>();

  useEffect(() => {
    if (countdown > 0) {
      timerRef.current = setInterval(() => {
        setCountdown((prev) => Math.max(prev - 1, 0));
      }, 1000);
    }

    return () => {
      timerRef.current && clearInterval(timerRef.current);
    };
  }, [countdown]);

  if (isButtonDisabled) {
    return (
      <p>
        {t('checkEmail.footer.repeatAfter')} {countdown}s
      </p>
    );
  }

  return (
    <StyledButton
      type="button"
      onClick={resetLink}
      view="link"
      disabled={isButtonDisabled}
      aria-disabled={isButtonDisabled}
    >
      {t('checkEmail.footer.resentLink')}
    </StyledButton>
  );
};
