import { Button, Input } from '@quotalogic/ui';
import { Eye, EyeSlash } from '@gravity-ui/icons';
import { useState } from 'react';

type Props = {
  value: string
  name: string
  message?: string
  onChange: (value: string) => void
  onBlur?: (value: boolean) => void
  validation?: boolean
}

export const PasswordInput = ({ name, message, value, onBlur, onChange, validation = true }: Props) => {
  const [isVisible, setIsVisible] = useState(false);
  const [isValid, setIsValid] = useState(true);

  const validatePassword = () => {
    if (validation) {
      const valid = value.trim() === '' || value.length > 7;
      setIsValid(valid);
      onBlur?.(valid);
    }
  };

  return (
    <Input
      type={isVisible ? 'text' : 'password'}
      name={name}
      required
      status={isValid ? 'default' : 'error'}
      message={!isValid ? message : undefined}
      onChange={onChange}
      onBlur={validatePassword}
    >
      <Button
        view="ghost"
        size="small"
        area-label="Show password"
        iconLeft={!isVisible ? <EyeSlash /> : <Eye />}
        onClick={() => setIsVisible(!isVisible)}
      />
    </Input>
  );
};
