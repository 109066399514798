import { ReactNode } from 'react';
import { Wrapper } from './styles';

type Props = {
  children: ReactNode;
}

export const LayoutForm = ({ children }: Props) => (
  <Wrapper>{children}</Wrapper>
);
