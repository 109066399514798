import styled from 'styled-components';
import { GlobalColors } from '@quotalogic/ui';

export const Form = styled.form`
  display: flex;
  width: 100%;
  flex-direction: column;
  gap: 12px;
`;

export const Title = styled.h1`
  font-size: 20px;
  font-style: normal;
  font-weight: 700;
  line-height: 24px; 
  text-align: center;
`;

export const TextStyle = styled.span`
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
  align-self: stretch;
  overflow: hidden;
  color: ${GlobalColors.text.primary};
  text-align: center;
  text-overflow: ellipsis;
  font-size: 14px;
  font-style: normal;
  font-weight: 700;
  line-height: 16px;
`;

export const SubTextStyle = styled.span`
   display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
  align-self: stretch;
  overflow: hidden;
  color: ${GlobalColors.text.secondary};
  text-align: center;
  text-overflow: ellipsis;
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: 16px;
`;

export const ButtonsBlock = styled.div`
  display: flex;
  justify-content: stretch;
  margin-top: 12px;
  gap: 12px;
    
   & > button {
      flex: 1;
    }
`;

export const LogoPicStyle = styled.div`
   align-self: center;
`;

export const SpinnerStyle = styled.div`
   align-self: center;
   color: ${GlobalColors.icon.primary};
`;
