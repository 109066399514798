import { useTranslation } from 'next-i18next';
import { useEffect } from 'react';
import { Spinner } from '@quotalogic/ui';
import { Text, Title } from './styles';

export const RecoverySuccess = () => {
  const { t } = useTranslation('password-recovery');

  useEffect(() => {
    const timer = setTimeout(() => {
      window.location.href = '/';
    }, 3000);

    return () => clearTimeout(timer);
  }, []);

  return (
    <>
      <Title>{t('successRecoveryPass.title')}</Title>
      <Text>{t('successRecoveryPass.text')}</Text>
      <div style={{ alignSelf: 'center' }}>
        <Spinner size={24} />
      </div>
    </>
  );
};
