import { useEffect } from 'react';
import { FetchResult, useMutation, useQuery } from '@apollo/client';
import { useTranslation } from 'next-i18next';
import { InheritEvent } from '@quotalogic/gateway/types/Catalog';
import { Button } from '@quotalogic/ui';
import { Workspace } from '@quotalogic/gateway/types';
import { INHERIT_CATALOG, GET_INHERIT_STATUS } from './gql';
import { inheritCatalogIdVar } from '../reactiveVar';

interface Props {
  token: string
  isDisabled?: boolean
  nextStep?: () => void
  callback?: () => Promise<FetchResult<{ createWorkspace: Workspace }> | null> | Promise<void>
  setIsConnecting: (arg: boolean) => void
  errorFn: () => void
}

export const ConnectCatalog = ({ token, isDisabled, callback, nextStep, setIsConnecting, errorFn }: Props) => {
  const { t } = useTranslation('connect-catalog');
  const [inheritCatalog, { data }] = useMutation<{ inheritCatalog: InheritEvent }>(INHERIT_CATALOG);
  const {
    data: inheritStatusData,
    stopPolling,
  } = useQuery<{ catalogInheritStatus: InheritEvent }>(GET_INHERIT_STATUS, {
    variables: {
      inheritId: data?.inheritCatalog.id,
    },
    skip: !data?.inheritCatalog.id,
    pollInterval: 1000,
  });

  const connectCatalog = async () => {
    setIsConnecting(true);
    try {
      await callback?.();
      await inheritCatalog({
        variables: { token },
      });
    } catch (e) {
      errorFn();
      setIsConnecting(false);
    }
  };

  useEffect(() => {
    if (inheritStatusData?.catalogInheritStatus.status === 'SUCCESS'
      || inheritStatusData?.catalogInheritStatus.status === 'SUCCESS_WITH_WARNINGS'
      || inheritStatusData?.catalogInheritStatus.status === 'ERROR'
    ) {
      stopPolling();
      if (inheritStatusData?.catalogInheritStatus.entityId
        && inheritStatusData?.catalogInheritStatus.status !== 'ERROR'
      ) {
        inheritCatalogIdVar(inheritStatusData.catalogInheritStatus.entityId);
        nextStep && nextStep();
      }
      if (inheritStatusData?.catalogInheritStatus.status === 'ERROR') {
        errorFn();
      }
      setIsConnecting(false);
    }
  }, [inheritStatusData?.catalogInheritStatus, nextStep, setIsConnecting, stopPolling, errorFn]);

  return (
    <Button type="button" onClick={connectCatalog} disabled={isDisabled}>
      {t('button.connect')}
    </Button>
  );
};
