import { Button, GlobalColors } from '@quotalogic/ui';
import styled from 'styled-components';

export const Title = styled.h2`
  text-align: center;
  font-size: 20px;
  font-weight: 700;
  line-height: 24px;
  color: ${GlobalColors.text.primary};
`;

export const FormStyle = styled.form`
  display: flex;
  flex-direction: column;
  gap: 16px;
`;

export const FooterLink = styled.div`
  display: inline-block;
  font-weight: 500;
  white-space: nowrap;
  text-align: center;
  font-size: 12px;
  line-height: 16px;

  & > button {
    padding: 0;
    margin-left: 4px;
    font-weight: 500;
    color: ${GlobalColors.text.accent};
    cursor: pointer;
  }
`;

export const Link = styled.a`
  padding: 0 4px;
  border-radius: 8px;
  line-height: 16px;
  font-weight: 500;
  color:  ${GlobalColors.text.accent};
`;

export const ButtonStyle = styled(Button)`
  align-self: center;
  justify-content: center;
`;

export const ErrorLayout = styled.div`
  text-align: center;
  color: ${GlobalColors.text.error};
`;

export const TextBlock = styled.div`
  text-align: center;
  font-size: 12px;
  font-weight: 500;
  line-height: 16px;
`;

export const ButtonBlock = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
`;
