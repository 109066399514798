import styled from 'styled-components';
import { GlobalColors, Button } from '@quotalogic/ui';

export const Title = styled.h2`
  color: ${GlobalColors.text.primary};
  font-size: 20px;
  font-weight: 700;
  line-height: 24px;
  text-align: center;
`;

export const TextContainer = styled.div`
  display: flex;
  flex-direction: column;
  text-align: center;
  
  &:first-of-type {
    border-bottom: 1px solid ${GlobalColors.border.secondary};
    padding: 0 0 16px 0;
  }
`;

export const StyledTopText = styled.span`
  font-size: 14px;
  font-weight: 400;
  line-height: 18px;
  color: ${GlobalColors.text.secondary};
`;

export const StyledEmail = styled(StyledTopText)`
  font-weight: 700;
  color: ${GlobalColors.text.primary};
`;

export const StyledBottomText = styled.span`
  font-size: 12px;
  font-weight: 500;
  line-height: 16px;
  color: ${GlobalColors.text.primary};
`;

export const StyledButton = styled(Button)`
  padding: 0 4px;
  color: ${GlobalColors.text.accent};
`;
