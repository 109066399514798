import { gql } from '@apollo/client';

export const GET_CATALOG = gql`
  query Catalog($id: ID!) {
    catalog(id: $id) {
      id
      name
      logo
      preferences {
        image
      }
      parent {
        brand {
          id
          brandName
        }
        catalogToken
        profileToken
      }
    }
  }
`;
