import { FormEvent, useState, useMemo } from 'react';
import { useTranslation } from 'next-i18next';
import { FormField } from '@quotalogic/ui';
import { useMutation } from '@apollo/client';
import { User } from '@quotalogic/gateway/types';
import {
  Title, FormStyle, ButtonStyle, FooterLink, Link,
} from './styles';
import { RESET_PASSWORD, RESTORE_PASSWORD } from './gql';
import { AuthStatusType } from '../../types';
import { PasswordInput } from '../../inputs';

interface Props {
  token: string | null
  email: string | null
  action: string | null
  onSetStatus: (status: AuthStatusType) => void
}

export const CreatePassword = ({ token, email, action, onSetStatus }: Props) => {
  const { t } = useTranslation('password-recovery');
  const { t: errorT } = useTranslation('notification');
  const [password, setPassword] = useState<string>('');
  const [repeatPassword, setRepeatPassword] = useState<string>('');
  const [resetPassword, { loading }] = useMutation<{ resetPassword: User }>(RESET_PASSWORD);
  const [restorePassword, { loading: restoreLoading }] = useMutation<{ restorePassword: User }>(RESTORE_PASSWORD);

  const isFormValid = useMemo(() => (
    !!password && !!repeatPassword && password === repeatPassword
  ), [password, repeatPassword]);

  const handleSubmit = async (e: FormEvent) => {
    e.preventDefault();
    if (!isFormValid) return;

    if (action === 'recovery') {
      await resetPassword({
        variables: { token, email, password },
        onCompleted(data) {
          if (data.resetPassword) {
            onSetStatus('RECOVERY_SUCCESS');
          } else {
            onSetStatus('TOKEN_EXPIRED');
          }
        },
      });
    }

    if (action === 'restore') {
      await restorePassword({
        variables: { restoreToken: token, email, password },
        onCompleted(data) {
          if (data.restorePassword) {
            onSetStatus('RECOVERY_SUCCESS');
          } else {
            onSetStatus('TOKEN_EXPIRED');
          }
        },
      });
    }
  };

  return (
    <>
      <Title>{t('createPassword.title')}</Title>
      <FormStyle onSubmit={handleSubmit}>
        <FormField name="password" label={t('createPassword.label.password')}>
          <PasswordInput
            name="password"
            value={password}
            onChange={(val) => setPassword(val)}
            message={errorT<string>('error.EAC_INVALID_PASSWORD_LENGTH')}
          />
        </FormField>

        <FormField name="repeat_password" label={t('createPassword.label.repeatPassword')}>
          <PasswordInput
            name="repeat_password"
            value={repeatPassword}
            onChange={(val) => setRepeatPassword(val)}
            message={errorT<string>('error.EAC_PASSWORD_NOT_CONFIRMED')}
          />
        </FormField>

        <ButtonStyle type="submit" loading={loading || restoreLoading} disabled={!isFormValid}>
          {t('createPassword.button')}
        </ButtonStyle>
      </FormStyle>

      <FooterLink>
        {t('passwordRecovery.footerText')}
        <Link href="/login">{t('passwordRecovery.link')}</Link>
      </FooterLink>
    </>
  );
};
