import { Input } from '@quotalogic/ui';
import { useState } from 'react';

type Props = {
  value: string
  message: string
  onChange: (value: string) => void;
  onBlur?: (value: boolean) => void
}

export const EmailInput = ({ value, message, onChange, onBlur }: Props) => {
  const [isValid, setIsValid] = useState(true);

  const validateEmail = () => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    setIsValid(value.trim() === '' || emailRegex.test(value));
    onBlur?.(value.trim() === '' || emailRegex.test(value));
  };

  return (
    <Input
      type="text"
      name="email"
      status={isValid ? 'default' : 'error'}
      message={!isValid ? message : undefined}
      aria-invalid="true"
      aria-errormessage="error-message"
      required
      onChange={onChange}
      onBlur={validateEmail}
    />
  );
};
