import { useState } from 'react';
import { CheckEmail } from '../CheckEmail';
import { ActivateAccount } from './ActivateAccount';
import { TokenExpired } from '../TokenExpired';
import { AuthStatusType } from '../types';
import { RegisterForm } from './RegisterForm';
import { InvalidInvitation } from '../InvalidInvitation';
import { LoginForm } from '../Login/LoginForm';
import { ActivationDataType } from '../utils';
import { CreatePassword } from '../PasswordRecovery/CreatePassword';
import { RecoverySuccess } from '../PasswordRecovery/RecoverySuccess';

type Props = {
  authStatus: AuthStatusType
  appName: string
  activationEmail: string | null
  activationToken: string | null
  action: string | null
  activationData: ActivationDataType | null
}

export const Register = ({ authStatus, appName, activationToken, activationEmail, action, activationData }: Props) => {
  const [email, setEmail] = useState(activationEmail);
  const [status, setStatus] = useState<AuthStatusType>(authStatus);

  switch (status) {
    case 'REGISTER':
      return <RegisterForm appName={appName} onSetStatus={setStatus} onSetEmail={setEmail} />;

    case 'ACTIVATION':
      return <ActivateAccount email={email} token={activationToken} action={action} appName={appName} onSetStatus={setStatus} activationData={activationData} />;

    case 'TOKEN_EXPIRED':
      return <TokenExpired email={email} onSetStatus={setStatus} />;

    case 'CHECK_EMAIL':
      return <CheckEmail email={email} action="REGISTER" />;

    case 'INVALID_INVITE':
      return <InvalidInvitation onSetStatus={setStatus} />;

    case 'LOGIN':
      return <LoginForm appName={appName} onSetStatus={setStatus} />;

    case 'CREATE_NEW_PASSWORD': {
      return <CreatePassword token={activationToken} email={email} onSetStatus={setStatus} action={action} />;
    }

    case 'RECOVERY_SUCCESS':
      return <RecoverySuccess />;

    default:
      return null;
  }
};
