import { FormEvent, useState, useMemo } from 'react';
import { FormField } from '@quotalogic/ui';
import { useTranslation } from 'next-i18next';
import { useMutation } from '@apollo/client';
import { UserSession } from '@quotalogic/gateway/types';
import {
  ButtonStyle, FooterLink, LinkButton, FormStyle, Link, Title, FieldsContainer, ButtonBlock, StyledError,
} from './styles';
import { SIGN_IN } from './gql';
import { EmailInput, PasswordInput } from '../../inputs';

type SignIn = (email: string, password: string) => void;

interface Props {
  appName: string
  onSetEmail?: (email: string) => void
  onSetStatus?: (status: 'CHECK_EMAIL') => void
}

export const LoginForm = ({ appName, onSetEmail, onSetStatus }: Props) => {
  const { t } = useTranslation('login');
  const { t: errorT } = useTranslation('notification');
  const [email, setEmail] = useState<string>('');
  const [password, setPassword] = useState<string>('');
  const [error, setError] = useState<string | null>(null);

  const [login, { loading }] = useMutation<{ signIn: UserSession }>(SIGN_IN, { errorPolicy: 'all' });

  const isFormValid = useMemo(() => !!email.trim() && !!password.trim(), [email, password]);

  const signIn: SignIn = async () => {
    await login({
      variables: { email, password },
      onError: (err) => {
        if (err.graphQLErrors?.some((e) => e?.extensions?.code === 'EAC_ACCOUNT_DISABLED' || e?.extensions?.code === 'EAC_ACCOUNT_DELETED')) {
          onSetEmail && onSetEmail(email);
          onSetStatus && onSetStatus('CHECK_EMAIL');
          return;
        }

        if (err.graphQLErrors?.some((e) => e?.extensions?.code === 'EAC_INVALID_USER_DATA')) {
          setError(err.graphQLErrors[0]?.extensions?.code as string | undefined ?? 'EAC_INVALID_USER_DATA');
        }
      },
      onCompleted: (data) => {
        if (data?.signIn) {
          setError(null);
          window.location.href = '../';
        }
      },
    });
  };

  const handleSubmit = (e: FormEvent) => {
    e.preventDefault();
    signIn && signIn(email, password);
  };

  return (
    <>
      <Title>
        {t('title', { name: appName })}
      </Title>

      <FormStyle onSubmit={handleSubmit}>
        <FieldsContainer>
          <FormField
            name="email"
            label={t('label.email')}
          >
            <EmailInput
              value={email}
              message={errorT<string>('error.EAC_INVALID_EMAIL')}
              onChange={(val) => setEmail(val)}
            />
          </FormField>

          <FormField
            name="password"
            label={t('label.password')}
            buttons={<LinkButton href="/password-recovery">{t('links.forgotPass')}</LinkButton>}
          >
            <PasswordInput
              value={password}
              name="password"
              onChange={(val) => setPassword(val)}
              validation={false}
            />
          </FormField>
        </FieldsContainer>
        <ButtonBlock>
          <ButtonStyle type="submit" disabled={!isFormValid} loading={loading}>
            {t('button')}
          </ButtonStyle>
          {error && <StyledError id="error-message">{errorT('error.EAC_INVALID_USER_DATA')}</StyledError>}
        </ButtonBlock>
      </FormStyle>

      <FooterLink>
        {t('accountNotExists')}<Link href="/register">{t('links.sighUp')}</Link>
      </FooterLink>
    </>
  );
};
