export type PasswordState = {
  value: string
  isValid: boolean
}

type PasswordAction = { type: 'SET_VALUE', payload: string } | { type: 'SET_VALIDITY', payload: boolean }

export const passwordReducer = (state: PasswordState, action: PasswordAction) => {
  switch (action.type) {
    case 'SET_VALUE':
      return { ...state, value: action.payload };

    case 'SET_VALIDITY':
      return { ...state, isValid: action.payload };

    default:
      return state;
  }
};
