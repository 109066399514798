import { AuthStatusType } from '../types';

type AuthStatusParams = {
  fallbackStatus: AuthStatusType
  email?: string
  token?: string
  action?: string
}

export type ActivationDataType = {
  accountStatus: 'ACTIVE' | 'UNVERIFIED' | 'DISABLED' | 'SUSPEND'
  workspaceId?: string
  workspaceName?: string
  workspaceLogo?: string
  activationToken?: string
  inviteToken?: string
  userId?: string
}

export const getAuthStatus = async ({ email, token, fallbackStatus, action }: AuthStatusParams): Promise<{ status: AuthStatusType, data?: ActivationDataType }> => {
  let authStatus = fallbackStatus;
  let authData;

  switch (action) {
    case 'activate':
      if (email && token) {
        try {
          const response = await fetch(
            `${process.env.ACCOUNT_API}/v1/activate/status?email=${email}&token=${token}`,
          );
          const isValid = response.status === 204;
          authStatus = isValid ? 'ACTIVATION' : 'TOKEN_EXPIRED';
        } catch (e: unknown) {
          authStatus = 'TOKEN_EXPIRED';
        }
      } else {
        authStatus = 'REGISTER';
      }
      break;

    case 'recovery':
      if (!email && !token) {
        authStatus = 'RECOVERY';
      } else if (email && token) {
        try {
          const response = await fetch(
            `${process.env.ACCOUNT_API}/v1/password-recovery/status?email=${email}&token=${token}`,
          );
          const isValid = response.status === 204;
          authStatus = isValid ? 'CREATE_NEW_PASSWORD' : 'TOKEN_EXPIRED';
        } catch (e: unknown) {
          authStatus = 'TOKEN_EXPIRED';
        }
      }
      break;

    case 'invite':
      if (!email && !token) {
        authStatus = 'INVALID_INVITE';
      } else if (email && token) {
        try {
          const response = await fetch(
            `${process.env.ACCOUNT_API}/v1/activate/invite/status?email=${email}&token=${token}`,
          );
          if (response.status === 200) {
            authData = await response.json();
            authStatus = authData.accountStatus === 'DISABLED' ? 'INVALID_INVITE' : 'ACTIVATION';
          } else {
            authStatus = 'INVALID_INVITE';
          }
        } catch (e: unknown) {
          authStatus = 'INVALID_INVITE';
        }
      }
      break;

    case 'restore':
      if (!email && !token) {
        authStatus = 'TOKEN_EXPIRED';
      } else if (email && token) {
        try {
          const response = await fetch(
            `${process.env.ACCOUNT_API}/v1/restore/status?email=${email}&token=${token}`,
          );
          const isValid = response.status === 204;
          authStatus = isValid ? 'CREATE_NEW_PASSWORD' : 'TOKEN_EXPIRED';
        } catch (e: unknown) {
          authStatus = 'INVALID_INVITE';
        }
      }
      break;

    default:
      authStatus = fallbackStatus;
      break;
  }

  return { status: authStatus, data: { ...authData?.data as ActivationDataType } };
};
